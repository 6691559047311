<template>
  <div class="mobilePage">
    <div class="floatingBox">
      <div class="btn1" @click.stop="toOfficial"></div>
      <div class="btn2" @click.stop="toPotato"></div>
      <div class="btn3" @click.stop="toBusiness"></div>
      <div class="btn4" @click.stop="toChannel"></div>
    </div>
    <div class="main">
      <!-- 移动端 -->
      <div class="header">
        <div class="mobileLogo"></div>
        <!-- <div
          class="contactOfficialBtn"
          @click="jumpGroup('https://t.me/peachccc')"
        ></div> -->
      </div>
      <div class="sizeBox"></div>
      <div class="swiperBox">
        <van-swipe class="my-swipe" :autoplay="2000" :show-indicators="false">
          <van-swipe-item>
            <div class="figure1"></div>
          </van-swipe-item>
          <van-swipe-item>
            <div class="figure2"></div>
          </van-swipe-item>
          <van-swipe-item>
            <div class="figure3"></div>
          </van-swipe-item>
          <van-swipe-item>
            <div class="figure4"></div>
          </van-swipe-item>
          <van-swipe-item>
            <div class="figure5"></div>
          </van-swipe-item>
          <van-swipe-item>
            <div class="figure6"></div>
          </van-swipe-item>
          <van-swipe-item>
            <div class="figure7"></div>
          </van-swipe-item>
        </van-swipe>
        <div class="logoText"></div>
        <div class="mobileBtn" @click="getApkInfo"></div>
      </div>
      <div class="figureBox"></div>
      <!-- <div class="connectBox">
        <div
          class="telegram"
          @click="jumpGroup('https://t.me/peachccc')"
        ></div>
        <div
          class="twitter"
          @click="jumpGroup('https://twitter.com/peach_media8')"
        ></div>
        <div
          class="potato"
          @click="jumpGroup('https://ptcc.in/mitaoyingxiangchuanmei123')"
        ></div>
      </div> -->
      <div class="kindTip"></div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["os"],
  data() {
    return {
      androidPromptShow: false,
      iosPromptShow: false,
      showTip: false,
      currUrl: window.location.href,
      tabActive: "",
      tabs: [
        {
          label: "漫画",
          value: "comics",
        },
        {
          label: "动漫",
          value: "cartoon",
        },
        {
          label: "影视",
          value: "movie",
        },
      ],
    };
  },
  mounted() {
    let ua = this.os.userAgent.toLowerCase();
    let isSafari =
      ua.indexOf("safari") !== -1 &&
      ua.indexOf("browser") === -1 &&
      ua.indexOf("android") === -1;
    // alert(ua)
    console.log(ua);
    console.log(isSafari);
    // console.log(ua.indexOf('safari'), "------");
    if (!isSafari && this.os.isPhone) {
      this.showTip = true;
      // console.log(this.showTip, '-------------')
      // alert('请使用Safari浏览器进行下载')
    }
    const referrer = sessionStorage.getItem("referrer");
    const dc = sessionStorage.getItem("dc");
    if (referrer && dc) {
      this.currUrl = referrer + "user.html?dc=" + dc;
    } else {
      if (referrer) {
        this.currUrl = referrer + "user.html";
      } else {
        this.currUrl = window.location.href;
      }
    }
  },
  methods: {
    // 剪贴板
    doCopy() {
      this.$copyText(this.currUrl).then(
        () => {
          console.log("复制成功");
          this.showTip = false;
        },
        () => {
          console.log("复制失败");
        }
      );
    },
    async showInstallTutorial(type) {
      if (type == "ios") {
        this.iosPromptShow = true;
      } else {
        this.androidPromptShow = true;
      }
    },
    async getApkInfo() {
      let type = "android";
      if (this.os.isPhone) {
        type = "ios";
      } else {
        type = "android";
      }
      await this.showInstallTutorial(type);
      setTimeout(() => {
        this.$emit("getApkInfo", type);
      }, 500);
    },
    jumpGroup(url) {
      window.location.href = url;
    },

    toOfficial() {
      window.open("https://t.me/peachccc");
    },
    toPotato() {
      window.open("https://dlj199.org/mitaoyingxiangchuanmei123");
    },
    toBusiness() {
      window.open("https://t.me/mu02guang");
    },
    toChannel() {
      window.open("https://t.me/huansong_nangua");
    },
  },
};
</script>

<style lang="scss" scoped>
.mobilePage {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  overflow: hidden;
  overflow-y: auto;
  position: relative;
  .floatingBox {
    height: 264px;
    width: 55px;
    position: fixed;
    right: 0;
    top: 190px;
    background: url("./../../../assets/images/mobile1/floatingBox.png")
      no-repeat;
    background-size: 100% 100%;
    z-index: 10000;
    .btn1 {
      height: 42px;
      width: 42px;
      position: absolute;
      left: 6px;
      top: 30px;
    }
    .btn2 {
      height: 42px;
      width: 42px;
      position: absolute;
      left: 6px;
      top: 84px;
    }
    .btn3 {
      height: 42px;
      width: 42px;
      position: absolute;
      left: 6px;
      bottom: 84px;
    }
    .btn4 {
      height: 42px;
      width: 42px;
      position: absolute;
      left: 6px;
      bottom: 30px;
    }
  }
  .main {
    height: 2125px;
    width: 100%;
    background: url("./../../../assets/images/mobile1/bg.webp") no-repeat;
    background-size: 100% 100%;
    .header {
      height: 60px;
      width: 100%;
      background-color: rgba(193, 133, 163, 0.5);
      position: fixed;
      top: 0;
      left: 0;
      z-index: 10;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 17px 0 28px;
      box-sizing: border-box;
      .mobileLogo {
        height: 41px;
        width: 130px;
        background: url("./../../../assets/images/mobile1/logoMobile.png")
          no-repeat;
        background-size: 100% 100%;
      }
      .contactOfficialBtn {
        height: 25px;
        width: 83px;
        background: url("./../../../assets/images/mobile1/contactOfficial.png")
          no-repeat;
        background-size: 100% 100%;
      }
    }
    .sizeBox {
      height: 0.5px;
    }
    .swiperBox {
      position: relative;
      height: 630px;
      width: 100%;
      .my-swipe .van-swipe-item {
        .figure1 {
          height: 570px;
          width: 350px;
          background: url("./../../../assets/images/mobile1/figure1.webp")
            no-repeat;
          background-size: 100% 100%;
          margin: 0 auto;
        }
        .figure2 {
          height: 572px;
          width: 347px;
          background: url("./../../../assets/images/mobile1/figure2.webp")
            no-repeat;
          background-size: 100% 100%;
          margin: 0 auto;
        }
        .figure3 {
          height: 558px;
          width: 355px;
          background: url("./../../../assets/images/mobile1/figure3.webp")
            no-repeat;
          background-size: 100% 100%;
          margin: 0 auto;
        }
        .figure4 {
          height: 570px;
          width: 340px;
          background: url("./../../../assets/images/mobile1/figure4.webp")
            no-repeat;
          background-size: 100% 100%;
          margin: 0 auto;
        }
        .figure5 {
          height: 572px;
          width: 340px;
          background: url("./../../../assets/images/mobile1/figure5.webp")
            no-repeat;
          background-size: 100% 100%;
          margin: 0 auto;
        }
        .figure6 {
          height: 579px;
          width: 354px;
          background: url("./../../../assets/images/mobile1/figure6.webp")
            no-repeat;
          background-size: 100% 100%;
          margin: 0 auto;
        }
        .figure7 {
          height: 587px;
          width: 316px;
          background: url("./../../../assets/images/mobile1/figure7.webp")
            no-repeat;
          background-size: 100% 100%;
          margin: 0 auto;
        }
      }
      .logoText {
        height: 215px;
        width: 326px;
        background: url("./../../../assets/images/mobile1/logoText.png")
          no-repeat;
        background-size: 100% 100%;
        position: absolute;
        z-index: 9;
        left: 50%;
        bottom: 50px;
        transform: translate(-50%, 0);
      }
      .mobileBtn {
        height: 48px;
        width: 180px;
        background: url("./../../../assets/images/mobile1/mobileBtn.png")
          no-repeat;
        background-size: 100% 100%;
        position: fixed;
        z-index: 9;
        left: 50%;
        bottom: 30px;
        transform: translate(-50%, 0);
      }
    }
    .figureBox {
      height: 1165px;
      width: 303px;
      background: url("./../../../assets/images/mobile1/figure.webp") no-repeat;
      background-size: 100% 100%;
      margin: 102px auto 0;
    }
    .connectBox {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 50px;
      .telegram {
        height: 25px;
        width: 25px;
        background: url("./../../../assets/images/mobile1/telegram.png")
          no-repeat;
        background-size: 100% 100%;
        margin-right: 12px;
      }
      .twitter {
        height: 25px;
        width: 25px;
        background: url("./../../../assets/images/mobile1/twitter.png")
          no-repeat;
        background-size: 100% 100%;
        margin-right: 12px;
      }
      .potato {
        height: 25px;
        width: 25px;
        background: url("./../../../assets/images/mobile1/potato.png") no-repeat;
        background-size: 100% 100%;
      }
    }
    .kindTip {
      height: 21px;
      width: 324px;
      background: url("./../../../assets/images/mobile1/kindTip.png") no-repeat;
      background-size: 100% 100%;
      margin: 25px auto 0;
    }
  }
}
</style>
