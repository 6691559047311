<template>
  <div class="pcm-wrapper" id="pcm-wrapper">
    <div class="pf-video">
      <video id="pfVideo" muted autoplay loop playsinline>
        <source :src="videoSrc" type="video/mp4" />
      </video>
    </div>
    <div class="mask"></div>
    <div class="pcLogo"></div>
    <div class="pcInfoBox">
      <div class="pcInfoTop">
        <div class="qrBox">
          <img class="qrcodeBox" :src="qrImg" alt="" />
        </div>
        <div class="btnBox">
          <div class="iosBtn"></div>
          <div class="androidBtn"></div>
        </div>
      </div>
      <div class="pcInfoBottom"></div>
    </div>
    <div class="pcConnectBox">
      <div class="telegram" @click="jumpGroup('https://t.me/peachccc')"></div>
      <!-- <div
        class="twitter"
        @click="jumpGroup('https://twitter.com/peach_media8')"
      ></div> -->
      <div
        class="potato"
        @click="jumpGroup('https://dlj199.org/mitaoyingxiangchuanmei123')"
      ></div>
      <div class="business" @click="jumpGroup('https://t.me/mu02guang')"></div>
      <div
        class="channel"
        @click="jumpGroup('https://t.me/huansong_nangua')"
      ></div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["qrImg"],
  data() {
    return {
      videoSrc: require("@/assets/video/video.mp4"),
    };
  },
  methods: {
    jumpGroup(url) {
      window.location.href = url;
    },
  },
};
</script>

<style lang="scss" scoped>
.pcm-wrapper {
  width: 100vw;
  height: 100vh;
  background: skyblue;
  .pf-video {
    position: absolute;
    width: 100%;
    height: 100%;
  }
  .mask {
    position: absolute;
    background: url("./../../../assets/images/pc/mask.webp") no-repeat;
    background-size: 100% 100%;
    width: 100%;
    height: 100%;
  }
  .pcLogo {
    position: fixed;
    top: 8px;
    right: 30px;
    width: 337px;
    height: 148px;
    background: url("./../../../assets/images/pc/logo.png") no-repeat;
    background-size: 100% 100%;
  }
  .pcInfoBox {
    position: fixed;
    left: 68px;
    bottom: 66px;
    .pcInfoTop {
      display: flex;
      align-items: flex-end;
      .btnBox {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 210px;
        padding: 10px 0;
        box-sizing: border-box;
        .iosBtn {
          width: 290px;
          height: 77.8px;
          background: url("./../../../assets/images/pc/iosBtn.png") no-repeat;
          background-size: 100% 100%;
        }
        .androidBtn {
          width: 290px;
          height: 77.8px;
          background: url("./../../../assets/images/pc/androidBtn.png")
            no-repeat;
          background-size: 100% 100%;
        }
      }
      .qrBox {
        width: 210px;
        height: 210px;
        background: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 20px;
        position: relative;
        .qrcodeBox {
          position: absolute;
          left: 10px;
          top: 10px;
          height: 190px;
          width: 190px;
          background-color: white;
        }
      }
    }
    .pcInfoBottom {
      height: 30px;
      width: 515px;
      background: url("./../../../assets/images/pc/ucTip.png") no-repeat;
      background-size: 100% 100%;
      margin-top: 24px;
    }
  }
  .pcConnectBox {
    position: fixed;
    right: 183px;
    bottom: 66px;
    display: flex;
    justify-content: center;
    align-items: center;
    .telegram {
      height: 68px;
      width: 68px;
      background: url("./../../../assets/images/pc/telegram.png") no-repeat;
      background-size: 100% 100%;
      margin-right: 32px;
      cursor: pointer;
    }
    // .twitter {
    //   height: 68px;
    //   width: 68px;
    //   background: url("./../../../assets/images/pc/twitter.png") no-repeat;
    //   background-size: 100% 100%;
    //   margin-right: 32px;
    //   cursor: pointer;
    // }
    .potato {
      height: 68px;
      width: 68px;
      background: url("./../../../assets/images/pc/icon-potato.png") no-repeat;
      background-size: 100% 100%;
      margin-right: 32px;
      cursor: pointer;
    }
    .business {
      height: 68px;
      width: 68px;
      background: url("./../../../assets/images/pc/icon-business.png") no-repeat;
      background-size: 100% 100%;
      margin-right: 32px;
      cursor: pointer;
    }
    .channel {
      height: 68px;
      width: 68px;
      background: url("./../../../assets/images/pc/icon-channel.png") no-repeat;
      background-size: 100% 100%;
      cursor: pointer;
    }
  }
}
</style>
